import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import './predecision-services.scss';
import Container from '@material-ui/core/Container';
import technology_graphics from '../../../assets/technology-graphics.png';
import technology_transform from '../../../assets/technology-transform-image2.png';
import global_service_teamwork_img from '../../../assets/global-service-teamwork.png';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export default function PreDecisionService() {
  
  return (
    <>
      <Grid className=''>
      <Container maxWidth="xl" className='zero-padding-div '>
        <Grid container className='predecision-technology-main'>
          <Grid item xs={12} sm={12} md={10} lg={7} xl={7}>
            <h2 className='tech-title1'>you are covered! our pre-decision services</h2>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={7} className="pre-decision-technology">
            <h3 className='tech-sub-title'>act on your business transformation now</h3>
            <p className='tech-desptxt'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
            <p className='tech-desptxt'>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={5} className="technology-grapics">
            <img src={technology_graphics} alt="where mobility meets agility" />
            <p className="download-bold">Download</p>


          </Grid>
        </Grid>
        </Container>
      </Grid>

      <Grid className=''>
      <Container maxWidth="xl" className='zero-padding-div '>
        <Grid container className='predecision-technology-main-transform'>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className="technology-grapics-transform">
            <img src={technology_transform} alt="where mobility meets agility" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className="pre-decision-technology-transform">
            <h3 className='tech-transform-sub-title'>our leap of faith to transform your business</h3>
            <p className='tech-transform-desptxt'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
          </Grid>
        </Grid>
        </Container>
      </Grid>

      {/* Let's Talk Section Start  */}
      <div className="lets-talk-tech-cls">
        <div className="letstalk-block">
          <div className="overlay">
            <Container maxWidth="xl" className='zero-padding-div '>
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="details">
                    <Grid item container xl={5} lg={5} md={6} sm={10} xs={11} >
                      <h2 className="title">next in technology <br/>interested in moveazy ?</h2>
                      <p className="para-tech-talk">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                      <Button variant="contained" className="btn-more">view moveazy</Button>
                    </Grid>
                  </div>

                </Grid>
              {/* </Grid> */}
            </Container>

          </div>

        </div>

      </div>
    </>
  )
}


