import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Container from '@material-ui/core/Container';


import global_service_header_img from '../../../assets/global-services-header.png';
// import global_service_teamwork_img from '../../../assets/global-service-teamwork.png';

import technology_image1 from '../../../assets/technology-sueLo-unsplash.png';
import technology_image2 from '../../../assets/technologyunsplash.png';
// import marketing_image from '../../../assets/marketing-collateral-infographic-1.png';
// import technology_graphics from '../../../assets/technology-graphics.png';
// import technology_transform from '../../../assets/technology-transform-image2.png';

import './header.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function Header() {

  const [dropdownEl, setdropdownEl] = React.useState(null);
  const dropdownOpen = Boolean(dropdownEl);
  const dropdownClick = (event) => {
    setdropdownEl(event.currentTarget);
  };
  const dropdownClose = () => {
    setdropdownEl(null);
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
    <Grid className='technology-content-cls'>
    <Container maxWidth="xl" className='zero-padding-div '>
        <Grid container className='header-container'>
          <Grid item xs={6} sm={6} md={5} lg={5} xl={5} className="header-left-text">
            <h1 className='title'>benefits builder</h1>
            <p>compassionate support. exceptional technology. unbound service. ‘that’s cartus experience’</p>
            {/* <p>exceptional technology. </p>
            <p>unbound service. </p>
            <p>‘that’s cartus experience’</p> */}
          </Grid>

          <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
            <div className='header-image'>
              <img src={global_service_header_img} alt="where mobility meets agility" width="100%" />
            </div>
          </Grid>

        </Grid>
        </Container>
      </Grid>

      <Container maxWidth="xl" className='zero-padding-div '>
      <Grid container item className='text-with-dropdown-div-technology'>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={7}>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          </p>
          <p>Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</p>

        <ul>
          <li> <p>Core/flex policy framework </p></li>
          <li> <p>Core/flex policy framework </p></li>
          <li> <p>Core/flex policy framework </p></li>
          <li> <p>Core/flex policy framework </p></li>
          <li> <p>Core/flex policy framework </p></li>
          <li> <p>Core/flex policy framework </p></li>
        </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={5} className="dropdown-div">
          {/* <FormControl sx={{ m: 1, minWidth: 120, width: 327}}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          className="menu-items"
          endIcon={<KeyboardArrowDownIcon />}
        >
          <MenuItem value="">
            pre-decision services
          </MenuItem>
          <MenuItem value={1}>a</MenuItem>
          <MenuItem value={2}>b</MenuItem>
          <MenuItem value={3}>c</MenuItem>
        </Select>        
      </FormControl> */}
          <Button
            id="dropdown"
            aria-controls={dropdownOpen ? 'pre-decision services' : undefined}
            aria-haspopup="true"
            aria-expanded={dropdownOpen ? 'true' : undefined}
            onClick={dropdownClick}
            className="navbar-btns"
          >
            <span className="dropdown-txt-bold">benefits builder</span>
            {dropdownOpen ?
              <ExpandLessIcon className="expand-icons-desk" />
              : <ExpandMoreIcon className="expand-icons-desk" />}
          </Button>
          <Menu
            id="dropdown-menu"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={dropdownEl}
            open={dropdownOpen}
            onClose={dropdownClose}
            MenuListProps={{
              'aria-labelledby': 'logins',
            }}
          >
            <MenuItem onClick={dropdownClose} className="menu-items">
              pre-decision services 1</MenuItem>
            <MenuItem onClick={dropdownClose} className="menu-items">
              pre-decision services 2</MenuItem>
            <MenuItem onClick={dropdownClose} className="menu-items">
              pre-decision services 3</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      </Container>

      <Grid className='technology-image-section-div'>
      <Container maxWidth="xl" className='zero-padding-div '>
        <Grid container className='technology-2-image-section'>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="image1-technology">
            <img src={technology_image1} alt="where mobility meets agility" />
            <p>team working on benefits builder</p>
          </Grid>


          <Grid item xs={12} sm={12} md={9} lg={6} xl={6} className="image2-technology">
            <div className="rootClass" >
          <div className='image-box'>
            <Paper elevation={0} className="video-content">
              <Button className="button-container" onClick={handleClickOpen}>
                <img src={technology_image2}
                  alt="video"
                  className="video-img" />
                <PlayCircleOutlineIcon fontSize="large" className="play-button-icon" />
              </Button>
              <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                <Box className="popup-overlay">
                  <IconButton className="popup-close-icon" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent >
                    <Grid container className='dialog-content'>
                      <div className="iframe-class">
                        <iframe
                          width="560"
                          height="349"
                          className="responsive-iframe"
                          src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                      </div>
                    </Grid>
                  </DialogContent>
                </Box>
              </Dialog>
              <Grid>
                <Paper elevation={0} className="intro-text-bg">
                <p className='para-bold'>our solutions</p>
            <p>cartus in new colours with tech-force</p>
                </Paper>
              </Grid>
            </Paper>
          </div>
          </div>
            {/* <img src={technology_image2} alt="where mobility meets agility" />
            <p className='para-bold'>our solutions</p>
            <p>cartus in new colours with tech-force</p> */}
          </Grid>

        </Grid>
        </Container>
      </Grid>

      

    </>
  )
}


