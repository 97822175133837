import React, { useState} from 'react';
import { Grid, Button, Slide, Dialog, DialogContent, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import request_form from '../../assets/request form.png';
import './RequestFormPopup.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

export default function RequestFormPopup() {
  const [open, setOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    })
  }
  //   useEffect(() => {
  //     window.addEventListener("scroll", () => {
  //         if (window.scrollY > 400) {
  //             setShowTopBtn(true);
  //         } else {
  //             setShowTopBtn(false);
  //         }
  //     });
  // }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid elevation={0} className='request-form-btn-div'>
      {showTopBtn && <Button className='request-form-btn' onClick={handleClickOpen}>request for more information
      </Button>}
      <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth='md'>
        <Box className='request-form-popup-overlay'>
          <div className='bg-donut'>
            <IconButton className='popup-close-icon' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogContent className='dialog-content'>
              <Grid container >
                <Grid items xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
                <Grid items xl={6} lg={6} md={12} sm={12} xs={12}>
                  <div className='title'>Request for more information</div>
                  <p className='desc'>Information includes all materials, collaterals, demo links as applicable</p>
                </Grid>
                <Grid items xl={6} lg={6} md={12} sm={12} xs={12} className='form-div'>
                  <img src={request_form} />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </Box>
      </Dialog>
      {/* <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
        <Box className='teams-biopopup-overlay'>
          <IconButton onClick={handleClose} className='popup-close-icon'>
            <CloseIcon className="black" />
          </IconButton>
          <DialogContent>
            <Grid
              container
              direction="row"
              spacing={4}
              style={{ height: '100%', marginTop: 0, marginBottom: 0 }}
               className='dialog-content'>
              <Grid
                direction="column"
                item
                lg={3}
                md={12}
                sm={12}
                className="shortDescription"
                alignItems={'flex-end'}>
                Quisque iaculis diam metus, nec auctor mauris finibus id. Nam sit amet magna vitae enim molestie dapibus sit amet sed orci. Proin venenatis lobortis sollicitudin. Praesent lacinia orci vitae dictum pretium
              </Grid>

              <Grid
                direction="column"
                item
                lg={5}
                md={12}
                sm={12}
                className="longDescription">
                  Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat. Sed vitae libero vestibulum, dictum turpis in, lobortis libero. In commodo vitae diam vel scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risus. Quisque fermentum nisl vitae quam rhoncus, quis dapibus quam tincidunt. Phasellus dictum rhoncus neque, eu hendrerit arcu convallis sed.
              </Grid>

              <Grid
                direction="column"
                item
                lg={4}
                md={12}
                sm={12}

                style={{ display: 'flex', alignItems: 'flex-end' }}
              >

                <img
                  src={video_1}
                  height={200}
                  width={200}
                  className="popupImage"
                  alt="Profile Image"
                />

                <div className="userinfo">
                  <a href="#" target="_blank">
                    <span className="linkedin">
                      alanmartinez@cartus.com
                    </span>
                  </a>
                  <div
                    className="AvenirNextBold"
                  >
                    <b>Glenda Sheffield-Adelie Haffeueser</b>
                  </div>


                  <div

                    className="customClassPopUp"
                  >
                    Glenda Sheffield-Adelie Haffeueser
                  </div>

                  <div

                    className="AvenirNextBold"
                  >
                    <b> alanmartinez@cartus.com</b>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog> */}
    </Grid>
  )

}
