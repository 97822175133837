import React from 'react'
import Layout from '../../staticcomponents/layout'
import './technology-content.scss'
import Header from './header'
import PreDecisionService from './predecision-services'
import TechnologyContentHighlighter from './TechnologyContentHighlighter';
import RequestFormPopup from '../../staticcomponents/RequestFormPopup';

export default function TechnologyContent() {
    return (
        <>
            <Layout>
                <div className='technology-main'>
                    <div className='bg-donut-left'>
                        <Header />
                        <TechnologyContentHighlighter/>
                        </div>   
                        <PreDecisionService/>
                            <RequestFormPopup/>
                </div>
                <RequestFormPopup/>
            </Layout>
        </>
    )
}