import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import './TechnologyContentHighlighter.scss';
import Container from '@material-ui/core/Container';
import marketing_image from '../../../assets/marketing-collateral-infographic-1.png';

export default function TechnologyContentHighlighter() {
  
  return (
    <>
       <Grid className=''>
        <Grid container className='marketing-technology-main'>
        <Container maxWidth="xl" className='zero-padding-div '>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="marketing-technology">
            <h3>our leap of faith to transform your business</h3>
            <img src={marketing_image} alt="where mobility meets agility" />
          </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  )
}


